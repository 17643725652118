import pagination from '../../utils/pagination'
import {
  EXTRAS_BATCH_DELETE_REQUEST, EXTRAS_BATCH_DELETE_SUCCESS, EXTRAS_BATCH_DELETE_FAILURE,
  EXTRAS_FETCH_MANY_REQUEST, EXTRAS_FETCH_MANY_SUCCESS, EXTRAS_FETCH_MANY_FAILURE,
  EXTRAS_FETCH_REQUEST, EXTRAS_FETCH_SUCCESS, EXTRAS_FETCH_FAILURE,
  EXTRAS_SAVE_REQUEST, EXTRAS_SAVE_SUCCESS, EXTRAS_SAVE_FAILURE,

  EMPTY_EXTRAS,
  CLEAR_ERRORS
} from './types'

export default {
  [EXTRAS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [EXTRAS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [EXTRAS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [EXTRAS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [EXTRAS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [EXTRAS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [EXTRAS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [EXTRAS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [EXTRAS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [EXTRAS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [EXTRAS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [EXTRAS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_EXTRAS] (state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS] (state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
