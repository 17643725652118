export const EXTRAS_BATCH_DELETE_REQUEST = 'EXTRAS_BATCH_DELETE_REQUEST'
export const EXTRAS_BATCH_DELETE_SUCCESS = 'EXTRAS_BATCH_DELETE_SUCCESS'
export const EXTRAS_BATCH_DELETE_FAILURE = 'EXTRAS_BATCH_DELETE_FAILURE'

export const EXTRAS_FETCH_MANY_REQUEST = 'EXTRAS_FETCH_MANY_REQUEST'
export const EXTRAS_FETCH_MANY_SUCCESS = 'EXTRAS_FETCH_MANY_SUCCESS'
export const EXTRAS_FETCH_MANY_FAILURE = 'EXTRAS_FETCH_MANY_FAILURE'

export const EXTRAS_FETCH_REQUEST = 'EXTRAS_FETCH_REQUEST'
export const EXTRAS_FETCH_SUCCESS = 'EXTRAS_FETCH_SUCCESS'
export const EXTRAS_FETCH_FAILURE = 'EXTRAS_FETCH_FAILURE'

export const EXTRAS_SAVE_REQUEST = 'EXTRAS_SAVE_REQUEST'
export const EXTRAS_SAVE_SUCCESS = 'EXTRAS_SAVE_SUCCESS'
export const EXTRAS_SAVE_FAILURE = 'EXTRAS_SAVE_FAILURE'

export const EMPTY_EXTRAS = 'EMPTY_EXTRAS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'