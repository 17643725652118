<template>
  <router-view />
</template>

<script>
import extraModule from '../../store/extras'

export default {
  beforeCreate () {
    this.$store.registerModule('extras', extraModule)
  },
  destroyed () {
    this.$store.unregisterModule('extras')
  }
}
</script>
